import React from "react"

import Layout from "../../../shared/components/layout/layout"
import WhrLogo from "../components/logos/whr-logo"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <div
      style={{
        textAlign: "center",
        maxWidth: 500,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 16,
        }}
      >
        <WhrLogo />
      </div>
      <h1>Wonder Horse Ranch</h1>
      <div style={{ marginBottom: 54, marginTop: 48 }}>
        <h4 style={{ marginBottom: 32 }}>
          Wonder Horse Ranch is moving to Oregon.
        </h4>
        <p>Programs are on hold until we find a new location in Oregon.</p>
        <p>
          Thank you Flying Pig Farm for providing a home over the past three
          years. Thank you to all our participants, volunteers, and donors.
        </p>
        <p>
          Updates will be posted on our website, Instagram and Facebook pages.
        </p>
      </div>
      <a href="mailto:info@wonderhorseranch.org">info@wonderhorseranch.org</a>
    </div>
  </Layout>
)

export default IndexPage
